<template>
  <div v-if="batch.length>0" class="p-2 deal-wrapper col-xl-4 col-lg-6 col-md-6 col-sm-12" >
    <div class="deal-inner-wrapper round-top">
      <div class="share-button" @click="() => showModal(mainDeal)" v-if="false">
        <img :src="`${speedSizeDomain}/assets/img/share-icon-white.png`" alt="share-icon" class="share-icon" width="23" height="20"/>
      </div>
      <div class="inner-box round-top" @click="gotoDealTabPage(mainDeal)">
        <img class="round-top" :src="imgLink(mainDeal)" :alt="mainDeal.title" >
        <ul class="tag">
            <li><img class="remark" :src="typeIcon(mainDeal)" /> {{type(mainDeal)}}</li>
            <li><img class="remark" :src="`${speedSizeDomain}/assets/img/app/suitcase-line.png`" /> {{baggage(mainDeal)}}</li>
            <li v-if="isTransfer(mainDeal)"><img class="remark" :src="`${speedSizeDomain}/assets/img/app/school-bus.png`" /> כולל העברות</li>
            <li v-if="isDailyTour(mainDeal)" class="mr-5 pointer" @click="showItineraryDetail(mainDeal)"><img class="remark" :src="`${speedSizeDomain}/assets/img/app/map.png`" /> מסלול הטיול</li>
            <li v-if="hasTitleRemark(mainDeal)" class="mr-4">{{ titleRemark(mainDeal) }}</li>
            <li v-if="basis(mainDeal).length>0" class="remark"><img class="remark" :src="basisIcon(mainDeal)" /> {{ basisName(mainDeal) }}</li>
        </ul>
        <div class="box-contain">
            <div class="text">
                <div class="text1 col-8 px-0">{{ title(mainDeal) }}</div>
                <div class="text1 col-4 px-0 align-self-end price text-left"><sub>החל מ-</sub>{{price(mainDeal)}}</div>
            </div>
            <p>{{subtitle(mainDeal)}}</p>
        </div>
      </div>
      <div class="deal-footer device">
        <div class="more-deals-wrapper" v-if="showExtraDeals">
          <div class="list-group">
              <div v-for="(dealGroup, index) in showingDealGroupList" :key=index class="group-wrapper">
                <div class="list-deal">
                  <div v-if="hideHeaderForSingleDeal ? dealGroup.length > 1 : true" class="group-header">
                    <span class="text date-caption">
                      {{groupHeaderDateCaption(dealGroup)}}
                    </span>
                    <span class="text price-caption" :class="{'transparent-color': showGroupDetailIndex === index}">
                      <span :class="{'transparent-color': showGroupDetailIndex === index}">החל מ-</span>
                      {{getLowestPriceInGroup(dealGroup)}}
                    </span>
                    <span class="text count-caption-box">
                      <span class="count-caption-wrapper">
                        <span class="count-caption" :class="{'transparent-color': dealGroup.length === 1}">{{dealGroup.length}}</span>
                        <span class="count-descript-caption">{{ addtionalFlightsCaption }}</span>
                      </span>
                      <i class="fa-solid"
                        :class="{'fa-angle-up': showGroupDetailIndex === index, 'fa-angle-down': showGroupDetailIndex !== index}"
                        @click="updateGroupDetailShow(index)">
                      </i>
                    </span>
                  </div>
                  <div v-if="showGroupDetailIndex == index || (hideHeaderForSingleDeal && dealGroup.length === 1)"
                    class="group-detail" :class="{'hidden-group-header': (hideHeaderForSingleDeal && dealGroup.length === 1)}">
                    <div v-for="(deal, idxInGroup) in dealGroup" :key=idxInGroup class="deal-item">
                      <div class="subtitle-wrapper">
                        <div class="text date-caption">{{depatureDateCaption(deal)}}</div>
                        <div class="text desc-caption">{{getDatesDescription(deal).description}}</div>
                      </div>
                      <span class="text price-caption">
                        <span>החל מ-</span>
                        {{ price(deal) }}
                      </span>
                      <div class="btn-select-flight" @click="gotoDealTabPage(deal)">הזמן עכשיו</div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="detail-nav-wrapper">
            <div class="btn-detail-nav-wrapper">
              <button class="btn-detail-nav prev" @click="navPrevGroupList" v-if="availablePrev"><i class="fa-solid fa-angle-right"></i> הקודם</button>
            </div>
            <div class="btn-detail-collapse-wrapper">
              <div class="collapse-btn" @click="hideExtraDeals"><i class="fa-solid fa-angle-up"></i></div>
            </div>
            <div class="btn-detail-nav-wrapper">
              <button class="btn-detail-nav next" @click="navNextGroupList" v-if="availableNext">הבא <i class="fa-solid fa-angle-left"></i></button>
            </div>
          </div>
        </div>
        <div class="show-detalis-btn-wrapper" v-else>
          <div v-if="batch.length > 1" class="btn-show-details" @click="showMoreExtraDeals">
            <div class="btn-detail-collapse-wrapper">
              <div class="collapse-btn"><i class="fa-solid fa-angle-down"></i></div>
            </div>
            <div class="btn-show-details-description">
              תאריכים נוספים
              <span class="batch-count">+{{moreDealCount || ''}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showItinerary" modal-class="itinerary-modal"
      centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true" :ok-only="true" :hide-footer="true">
      <div class="kremlindatelist-area" v-if="this.detailItinerary">
        <div class="kremlindatelistbox" v-for="(day, idx) in detailItinerary.itineraryDetail" :key="idx">
          <div class="media align-items-center">
            <figure class="media-img"><img src="/assets/img/app/icon4.png" alt="#" title=""></figure>
            <div class="media-body">
              <h3 class="tabs_blue_content_title text-right mr-2">{{ `יום ${day.day}: ${day.header}` }}</h3>
            </div>
          </div>
          <p class="description">{{day.text}}</p>
        </div>
      </div>
      <div v-else class="d-flex loading">
          <img class="m-auto" src="/assets/img/loading1.gif" alt="Loading..." />
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';
import {
  getId,
  getShareLink,
  getDestCode,
  getLinkImageDeal,
  getTitleDeal,
  getSubtitleDeal,
  getPriceDeal,
  getIsTransfer,
  getBaggage,
  getType,
  getTitleRemark,
  getBasis,
  getCategoryCode,
  getFormatDate,
} from '@/utils/bonauf/controllerCommon';
import imageUrlMixin from '@/utils/imageUrlMixin';

const { VUE_APP_BONAUF_DOMAIN_MOBILE } = process.env;

export default {
  name: 'AppDealBatch',
  mixins: [imageUrlMixin],
  components: {
    BModal,
  },
  props: {
    batch: {
      type: Array,
      default: () => [],
    },
    batchIndex: {
      type: Number,
      default: 0,
    },
    forceHideExtraDeals: {
      type: Boolean,
      default: false,
    },
    hideHeaderForSingleDeal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return ({
      showItinerary: false,
      detailItinerary: null,
      showExtraDeals: false,
      showGroupDetailIndex: -1,
      stepMoreGroup: 5,
      showMoreGroupIndex: 0,
    });
  },
  watch: {
    forceHideExtraDeals(newValue) {
      if (newValue) {
        this.showExtraDeals = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      testMode: 'GET_TEST_MODE',
      getDestinationName: 'GET_DEST_NAME',
      getCategoryName: 'GET_CATEGORY_NAME',
      // detailItinerary: 'GET_ITINERARY_DETAIL',
    }),
    mainDeal() {
      // find most lowest price deal for all batch
      let lowestPrice = Infinity;
      let lowestDeal = this.batch[0][0];

      for (let i = 0; i < this.batch.length; i++) {
        for (let j = 0; j < this.batch[i].length; j++) {
          const deal = this.batch[i][j];
          if (deal) {
            const price = deal.price_int;
            if (price < lowestPrice) {
              lowestPrice = price;
              lowestDeal = deal;
            }
          }
        }
      }

      return lowestDeal;
    },
    moreDealCount() {
      let count = 0;
      for (let i = 0; i < this.batch.length; i++) {
        count += this.batch[i].length;
      }
      return count-1;
    },
    dealGroupList() {
      const lowestDeal = this.mainDeal;
      // omit main deal
      const dealGroup = [];
      for (let i = 0; i < this.batch.length; i++) {
          const tmpGroup = [];
          for(let j = 0; j < this.batch[i].length; j++) {
            if(lowestDeal !== this.batch[i][j]) {
              tmpGroup.push(this.batch[i][j]);
            }
          }

          if(tmpGroup.length > 0) {
            dealGroup.push(tmpGroup);
          }
      }

      return dealGroup;
    },
    showingDealGroupList() {
      if(this.showMoreGroupIndex < 0) return [];
      return this.dealGroupList.slice(this.showMoreGroupIndex, this.showMoreGroupIndex + this.stepMoreGroup);
    },
    availableNext() {
      return this.showMoreGroupIndex + this.stepMoreGroup < this.dealGroupList.length - 1;
    },
    availablePrev() {
      return this.showMoreGroupIndex >= this.stepMoreGroup;
    },
    addtionalFlightsCaption() {
      const category = getCategoryCode(this.mainDeal);
      switch (category) {
        case '40400':
          return 'טיסות נוספות';
        case '41990':
          return 'טיולים נוספים';
        default:
          return 'חבילות נוספות';
      }
    }
  },
  created() {
  },
  methods: {
    isTransfer(pDeal) {
      return getIsTransfer(pDeal);
    },
    hasTitleRemark(pDeal) {
      return this.titleRemark(pDeal) !== '';
    },
    baggage(pDeal) {
      return getBaggage(pDeal);
    },
    isDailyTour(pDeal) {
      return getCategoryCode(pDeal) === '41990';
    },
    type(pDeal) {
      return getType(pDeal);
    },
    typeIcon(pDeal) {
      let iconPath = '';
      switch (getCategoryCode(pDeal)) {
        case '40400':
          iconPath = `${this.speedSizeDomain}/assets/img/app/flying.png`;
          break;
        case '41990':
          iconPath = `${this.speedSizeDomain}/assets/img/app/man.png`;
          break;
        default:
          iconPath = `${this.speedSizeDomain}/assets/img/app/vacationPack.png`;
      }
      return iconPath;
    },
    titleRemark(pDeal) {
      return getTitleRemark(pDeal);
    },
    basis(pDeal) {
      return getBasis(pDeal);
    },
    basisIcon(pDeal) {
      const codeBasis = this.basis(pDeal)?.[0]?.[0];
      let pathIcon = '';
      switch (codeBasis) {
        case 'HB':
          pathIcon = `${this.speedSizeDomain}/assets/img/app/dinner.png`;
          break;
        default:
          pathIcon = `${this.speedSizeDomain}/assets/img/app/breakfast.png`;
      }
      return pathIcon;
    },
    basisName(pDeal) {
      return this.basis(pDeal)?.[0]?.[1];
    },
    imgLink(pDeal) {
      return getLinkImageDeal(pDeal);
    },
    title(pDeal) {
      return getTitleDeal(pDeal);
    },
    subtitle(pDeal) {
      return getSubtitleDeal(pDeal);
    },
    getDatesDescription(pDeal) {
      const subTitle = this.subtitle(pDeal);
      if (!subTitle) return { dates: '', description: '' };

      const tokens = subTitle.split(' ');
      const dates = tokens[1];
      const description = `${tokens[0]} ${tokens.slice(2).join(' ')}`;

      return { dates, description };
    },
    price(pDeal) {
      return getPriceDeal(pDeal);
    },
    getLowestPriceInGroup(dealGroup) {
      const lowestPrice = Math.min(...dealGroup.map((deal) => deal.price_int));
      return `$${lowestPrice}`;
    },
    hideExtraDeals() {
      this.showExtraDeals = false;
    },
    updateGroupDetailShow(index) {
      if(index === this.showGroupDetailIndex) {
        this.showGroupDetailIndex = -1;
      } else {
        this.showGroupDetailIndex = index;
      }
    },
    showMoreExtraDeals() {
      this.showExtraDeals = true;
      this.showGroupDetailIndex = -1;
      this.showMoreGroupIndex = 0;

      // emit to parent to hide others
      this.$emit('emitMessage', {
        kind: 'shownExtraDeals',
        data: {
          index: this.batchIndex,
        },
      });

    },
    async showItineraryDetail() {
      this.showItinerary = true;
      if (this.detailItinerary === null && this.batch.length > 0 && this.isDailyTour(this.mainDeal)) {
        this.detailItinerary = await this.$store.dispatch('FETCH_ITINERARY_DETAIL', { id: this.mainDeal.id });
      }
    },
    gotoDealTabPage(pDeal) {
      const kind = window.location.pathname.includes('app-desktop') ? 'app-desktop' : 'app';
      if (!this.showItinerary) this.$router.push({ path: `/deal-tab/${kind}?id=${pDeal.id}&channel=App` });
    },
    showModal(pDeal) {
      let link = getShareLink(pDeal);
      link = link.replace(VUE_APP_BONAUF_DOMAIN_MOBILE, '');
      this.$emit('emitMessage', {
        kind: 'showShareModal',
        data: {
          id: getId(pDeal),
          link: encodeURI(`${link}&text=מצאתי דיל בבוא נעוף שיכול לעניין אותך, בוא נעוף ביחד`),
          category: this.getCategoryName(getCategoryCode(pDeal)),
          dest: this.getDestinationName(getDestCode(pDeal)),
          hotel: getTitleDeal(pDeal),
          image: getLinkImageDeal(pDeal),
          country: '',
        },
      });
    },
    groupHeaderDateCaption(group) {
      return getFormatDate(group[0].packStartDate, 'DD.MM.YY');
    },
    depatureDateCaption(pDeal) {
      return getFormatDate(pDeal.packStartDate, 'DD.MM.YY');
    },
    navNextGroupList() {
      if(this.availableNext) {
        this.showMoreGroupIndex += this.stepMoreGroup;
        this.showGroupDetailIndex = -1;
      }
    },
    navPrevGroupList() {
      if(this.availablePrev) {
        this.showMoreGroupIndex -= this.stepMoreGroup;
        this.showGroupDetailIndex = -1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
  .deal-wrapper {
    .round-top {
      border-radius: 20px 20px 0px 0px;
    }
    .round-all {
      border-radius: 20px;
    }

    .deal-inner-wrapper {
      position: relative;
      padding: 0px;

      .share-button {
        left: 12px;
        top: 12px;
        background: transparent;
      }
      .inner-box {
        height: 235px;
        display: flex;
        position: relative;

        &::before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: black;
          opacity: 0.3;
          cursor: pointer;
        }
      }
      .round-all.inner-box:before {
        border-radius: 20px;
      }
      .round-top.inner-box::before {
        border-radius: 20px 20px 0px 0px;
      }

      img{
        width: 100%;
        object-fit: cover;
        position: relative;
      }
      ul.tag {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 1;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          background: #FB6F2F;
          background: -moz-linear-gradient(270deg, #F9FD04 0%, #FB6F2F 100%);
          background: -webkit-linear-gradient(270deg, #F9FD04 0%, #FB6F2F 100%);
          background: linear-gradient(270deg, #F9FD04 0%, #FB6F2F 100%);
          box-sizing: border-box;
          border-radius: 30px;
          padding: 0px 5px;
          color: #000;
          font-family: 'ploni';
          margin: 5px 0 0 5px;
          font-size: 16px;

          img.remark {
            width: 15px;
            height: auto;
          }
        }
      }
      .box-contain{
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;

        .text {
          width: 100%;
          border-bottom: 1px solid white;
          margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
          padding: 6px;

          .text1{
            color: #ffffff;
            font-size: 16px;
            font-family: 'Rubik-Bold';

            sub {
              bottom: 1px;
              font-size: 75%;
              font-family: inherit;
              font-weight: normal;
            }
          }
          .text1.price {
            font-family: inherit;
            font-weight: bold;
            font-size: 20px;
          }
        }
        p {
          box-sizing: border-box;
          color: #ffffff;
          font-family: inherit;
          font-size: 20px;
          padding: 0 10px;
          margin: 0 0 6px;
        }
      }
      .deal-footer{
        width: 100%;
        background: -moz-linear-gradient(270deg, #F9FD04 0%, #FB6F2F 100%);
        background: -webkit-linear-gradient(270deg, #F9FD04 0%, #FB6F2F 100%);
        background: linear-gradient(270deg, #F9FD04 0%, #FB6F2F 100%);
        border-radius: 0px 0px 20px 20px;
        font-family: 'ploni';
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 6px 12px;

        .more-deals-wrapper {
          width: 100%;
          width: 100%;
          box-sizing: border-box;

          .list-group{
            background: #FFFFFFA1;
            border: 1px solid #FFFFFF;
            border-radius: 12px;
            margin: 4px 0px;
            padding: 0px 12px;
            overflow-y: auto;

            .group-wrapper {
              width: 100%;
              margin: 0 0 2px;
              border-bottom: solid 1px #0000002E;
              &:last-child {
                  border-bottom: none;
              }

              .list-deal {
                padding: 0px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;

                .group-header{
                  height: 30px;
                  display: flex;
                  justify-content: space-between;
                  font-size: 18px;
                  line-height: 28px;
                  margin: 9px 0px;
                  padding: 1px 0px;

                  .date-caption {
                    color: #353535;
                    padding-left: 10px;
                  }
                  .price-caption {
                    text-align: center;
                    color: #FB722F;
                    font-size: 18px;
                    font-weight: 700;

                    span{
                      color: #353535;
                      font-weight: 400;
                    }
                  }
                  .count-caption-box{
                    width: 135px;
                    display: flex;
                    justify-content: flex-end;
                    gap: 2px;
                    align-items: center;
                    font-size: 16px;
                    color: #FFFFFF;
                    background: #353535;
                    border-radius: 2px;
                    padding-left: 4px;

                    .count-caption-wrapper{
                      font-weight: 700;
                      .count-caption{
                        margin-left: 5px;
                      }
                    }
                    i {
                      cursor: pointer;
                    }
                  }

                  .transparent-color {
                    color: transparent !important;
                    text-shadow: none;
                  }
                }

                .group-detail {
                  display: flex;
                  flex-direction: column;

                  .deal-item {
                    height: 38px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 9px;

                    .subtitle-wrapper {
                      display: flex;
                      flex-direction: column;

                      .desc-caption{
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 400;
                      }
                    }
                    .price-caption {
                      margin-top: 9px;
                      color: #FB722F;
                      font-size: 18px;
                      font-weight: 700;
                      width: 135px;
                      span{
                        color: #353535;
                        font-weight: 400;
                      }
                    }
                    .btn-select-flight {
                      width: 100px;
                      color: #FFFFFF;
                      font-size: 16px;
                      font-weight: 700;
                      text-align: center;
                      border-radius: 2px;
                      background: #353535;
                      margin-top: 9px;
                      cursor: pointer;
                    }
                  }
                  &:not(.hidden-group-header) .deal-item {
                    border-top: solid 1px #0000002E;
                  }
                }
              }

              .text{
                box-sizing: border-box;
                color: #353535;
                font-weight: bold;
              }
            }
          }

          .detail-nav-wrapper {
            width: 196px;
            height: 44px;
            background: #FFFFFFA1;
            border: 1px solid #FFFFFF;
            border-radius: 12px;
            margin: 2px auto;
            display: flex;
            justify-content: center;

            .btn-detail-nav-wrapper {
              width: 62px;
              height: 24px;
              margin: auto;

              .btn-detail-nav {
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                color: #353535;
              }
            }
          }
        }

        .show-detalis-btn-wrapper{
          height: 44px;

          .btn-show-details {
            width: 196px;
            height: 100%;
            background: #FFFFFFA1;
            border: 1px solid #FFFFFF;
            border-radius: 12px;
            margin: 0px auto;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;

            .btn-show-details-description{
              font-size: 18px;
              font-weight: 400;
              color: #000000;
              .batch-count{
                font-weight: 700;
              }
            }
          }
        }

        .btn-detail-collapse-wrapper{
          width: 32px;
          height: 32px;
          border-radius: 32px;
          background: #353535;
          box-shadow: 0px 4px 8px 0px #00000040;
          margin: auto 0px;
          cursor: pointer;
          .collapse-btn {
            color: #FFFFFF;
            text-align: center;
            line-height: 36px;
          }
        }
      }
    }
  }
</style>
